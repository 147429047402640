import { useQuery } from '@tanstack/vue-query';
import { chatService } from '../utils/api';

export function useGetChatStatus() {
  const { data, isLoading } = useQuery({
    queryKey: ['chat-status'],
    queryFn: chatService.getChatStatus,
  });

  return {
    data,
    isLoading,
  };
}
