<script setup lang="ts">
import { MENU } from '~/utils/constants';

const route = useRoute();
const { data: user } = useUser();

const roleMenu = computed(() => {
  if (!user.value?.roles) return MENU;

  const role = user.value.roles[0].name;
  const menu = getRoleMenu(role);

  return MENU.filter((item) => {
    return menu.includes(item.id);
  });
});
</script>

<template>
  <nav class="relative z-10">
    <ul class="flex flex-col gap-2">
      <li v-for="(item, index) in roleMenu" :key="item.path" class="relative">
        <div>
          <NuxtLink
            v-if="!item.submenu.length"
            :to="item.path"
            class="text-bluegray-600 flex items-center space-x-2 text-sm font-semibold"
            :class="{
              'text-primary': route.path === item.path,
            }"
          >
            <PrimeButton
              class="flex w-full !justify-start gap-3 !p-2"
              :class="{
                'font-medium': route.path !== item.path,
                'font-bold': route.path === item.path,
              }"
              text
              :severity="route.path === item.path ? 'primary' : 'secondary'"
            >
              <i :class="item.icon"></i>
              <span class="text-nowrap">{{ item.title }}</span>
            </PrimeButton>
          </NuxtLink>

          <SubNavigation v-else :item="item" />
        </div>
      </li>
    </ul>
  </nav>
</template>
