<script setup lang="ts">
import { useGetChatStatus } from '~/chat/composables/useGetChatStatus';

const { data } = useGetChatStatus();
</script>

<template>
  <NuxtLink to="/chat">
    <PrimeButton class="aspect-square shrink-0 p-2" text severity="secondary">
      <PrimeOverlayBadge
        class="[&__.p-badge]:!p-0"
        :class="{
          '[&__.p-badge]:hidden': !data?.room_unread_count,
        }"
        :value="data?.room_unread_count"
        severity="danger"
      >
        <div
          class="flex aspect-square size-12 items-center justify-center rounded-full bg-white"
        >
          <i class="pi pi-comment text-surface-900 text-xl" />
        </div>
      </PrimeOverlayBadge>
    </PrimeButton>
  </NuxtLink>
</template>
