<script setup lang="ts">
import { ref } from 'vue';

const visible = ref(true);
const visibleSecondModal = ref(false);
const selectedTerms = ref([]);
const errorMessage = ref('');

const { data: terms } = useGetAllTerms();
const { mutate: acceptTerms, isPending } = useAcceptTerms();

const handleForm = () => {
  if (!terms.value) return;

  if (selectedTerms.value.length < terms.value.length) {
    errorMessage.value = 'Musisz zaznaczyć wszystkie zgody.';

    return;
  }

  acceptTerms(selectedTerms.value, {
    onSuccess: () => {
      errorMessage.value = '';
      selectedTerms.value = [];
      visible.value = false;
    },
  });
};

const showSecondModal = () => {
  visible.value = false;
  visibleSecondModal.value = true;
};

const showFirstModal = () => {
  visibleSecondModal.value = false;
  visible.value = true;
};
</script>

<template>
  <PrimeDialog
    v-model:visible="visible"
    modal
    :style="{ width: '684px', 'max-width': '90%' }"
    :closable="false"
  >
    <template #header>
      <div class="text-base font-medium text-gray-iron-900">
        Polityka prywatności
      </div>
    </template>

    <form class="space-y-4" @submit.prevent="handleForm">
      <div class="flex flex-col gap-3 text-gray-iron-700">
        <div
          :key="term.term_version_id"
          v-for="term in terms"
          class="flex gap-2"
        >
          <PrimeCheckbox
            v-model="selectedTerms"
            :inputId="String(term.term_version_id)"
            :value="term.term_version_id"
            @change="errorMessage = ''"
          />
          <label :for="String(term.term_version_id)">
            <div v-html="term.content"></div>
          </label>
        </div>

        <p v-if="errorMessage" class="text-sm text-red-500">
          {{ errorMessage }}
        </p>
      </div>

      <div class="flex justify-end gap-4">
        <PrimeButton
          label="Odrzuć"
          class="font-semibold"
          size="small"
          severity="secondary"
          rounded
          outlined
          @click="showSecondModal"
        />

        <PrimeButton
          type="submit"
          label="Akceptuje i kontynuuj"
          class="font-semibold"
          size="small"
          rounded
          :disabled="!!(isPending || errorMessage)"
        />
      </div>
    </form>
  </PrimeDialog>

  <PrimeDialog
    v-model:visible="visibleSecondModal"
    modal
    :style="{ width: '684px', 'max-width': '90%' }"
    :closable="false"
  >
    <template #header>
      <div class="text-base font-medium text-gray-iron-900">
        Polityka prywatności
      </div>
    </template>
    <div
      class="flex flex-col gap-3 font-normal tracking-wide text-gray-iron-700"
    >
      <div>
        Aby móc w pełni korzystać z systemu, konieczne jest zaakceptowanie zgód
        na przetwarzanie danych osobowych oraz zgód marketingowych. Bez Twojej
        zgody nie będziemy mogli zapewnić Ci dostępu do naszych usług.
      </div>
      <div>
        Jeśli chcesz zmienić zdanie, kliknij poniższy przycisk, aby wrócić i
        zaakceptować wymagane zgody. W każdej chwili możesz też zapoznać się z
        naszą [Polityką Prywatności], aby dowiedzieć się więcej.
      </div>
    </div>

    <div class="mt-4 flex justify-end gap-4">
      <PrimeButton
        type="submit"
        label="Wróć i zaakceptuj zgody"
        class="font-semibold"
        size="small"
        rounded
        @click="showFirstModal"
      />
    </div>
  </PrimeDialog>
</template>
