<script setup lang="ts">
const op = ref();

const {
  data: notifications,
  unreadNotifications,
  handleDeleteNotification,
  handleReadNotification,
} = useNotifications();
</script>

<template>
  <PrimeButton
    class="aspect-square shrink-0 p-2"
    text
    severity="secondary"
    @click="(event: MouseEvent) => op.toggle(event)"
  >
    <PrimeOverlayBadge
      class="[&__.p-badge]:!p-0"
      :class="{
        '[&__.p-badge]:hidden': !unreadNotifications.length,
      }"
      :value="unreadNotifications.length"
      severity="danger"
    >
      <div
        class="flex aspect-square size-12 items-center justify-center rounded-full bg-white"
      >
        <i class="pi pi-bell text-surface-900 text-xl" />
      </div>
    </PrimeOverlayBadge>
  </PrimeButton>

  <PrimePopover
    ref="op"
    class="max-h-[60vh] min-w-80 overflow-y-auto md:max-h-[80vh]"
  >
    <div class="w-full max-w-[456px] space-y-3">
      <ul v-if="notifications?.length" v-auto-animate class="divide-y-[1px]">
        <li
          v-for="notification in notifications"
          :key="notification.id"
          class="divide-y-2 divide-gray-2"
        >
          <NotificationBox
            :notification="notification"
            @delete="handleDeleteNotification(notification.id)"
            @read="handleReadNotification(notification.id)"
          />
        </li>
      </ul>

      <p v-else class="p-2 text-gray-iron-900">Brak powiadomień</p>
    </div>
  </PrimePopover>
</template>
