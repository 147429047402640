import { termsService } from '@/utils/api/termsService';
import { useMutation } from '@tanstack/vue-query';
import type { ResponseError } from '~/utils/responseError';
import { useQueryClient } from '@tanstack/vue-query';

export function useAcceptTerms() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: Number[]) => termsService.acceptTerms(payload),
    onSuccess: () => {
      queryClient.setQueryData(['user'], (user: User) => {
        if (!user) return;

        return {
          ...user,
          terms_accepted: true,
        };
      });

      toast.add({
        severity: 'success',
        summary: 'Regulamin został zaakceptowany',
        life: 3000,
      });
    },
    onError: (error: ResponseError) => {
      if (error.status === 422) {
        toast.add({
          severity: 'error',
          summary: error.message,
          life: 3000,
        });

        return;
      }

      toast.add({
        severity: 'error',
        summary: 'Wystąpił błąd podczas akceptowania regulaminu',
        life: 3000,
      });
    },
  });

  return {
    mutate,
    isPending,
  };
}
