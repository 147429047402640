<script setup lang="ts">
defineProps<{
  item: {
    icon: string;
    title: string;
    path: string;
    submenu: {
      title: string;
      path: string;
    }[];
  };
}>();

const route = useRoute();
const isOpen = ref(false);

function closeMenu() {
  isOpen.value = false;
}
</script>

<template>
  <div v-click-outside="closeMenu">
    <PrimeButton
      class="flex w-full items-center !justify-start gap-3 !p-2"
      text
      :class="{
        'font-medium': !isOpen,
        'text-primary-500 font-bold': isOpen,
      }"
      :severity="
        isOpen || route.path.includes(item.path) ? 'primary' : 'secondary'
      "
      @click="isOpen = !isOpen"
    >
      <i :class="item.icon"></i>
      <span class="text-nowrap">{{ item.title }}</span>
      <i v-if="isOpen" class="pi pi-angle-right ml-auto text-primary-600"></i>
    </PrimeButton>

    <div
      v-if="isOpen"
      class="absolute right-0 top-0 w-max min-w-[150px] translate-x-[180px]"
    >
      <div
        class="flex flex-col gap-1 rounded-2xl border border-gray-200 bg-white p-1"
      >
        <NuxtLink v-for="link in item.submenu" :key="link.path" :to="link.path">
          <PrimeButton
            class="w-full !justify-start whitespace-nowrap"
            text
            size="small"
            :severity="route.path === link.path ? 'primary' : 'secondary'"
            @click="closeMenu"
          >
            {{ link.title }}
          </PrimeButton>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
