import Echo from 'laravel-echo';
import { useQueryClient } from '@tanstack/vue-query';
import {
  updatePagesWithNewMessage,
  getFormattedEventMessage,
  updateRoomOrder,
  updateRoomsUsers,
  updateMessageUsers,
  unreadRoom,
  isRoomOnList,
} from '../utils/index';
import {
  messageAddedEventSchema,
  roomInvitedEventSchema,
} from '../utils/schemas';
import type { RoomDetails, Room } from '../utils/types';

export function useChatChannel() {
  const hostname = window.location.hostname.split('.')[0];

  const { $echo } = useNuxtApp() as unknown as { $echo: Echo<any> };
  const queryClient = useQueryClient();

  const { data: user } = useUser();

  watch(
    user,
    () => {
      if (
        user.value &&
        !user.value.roles.some((role) => role.name === 'admin')
      ) {
        $echo
          .private(`user.${hostname}.${user.value.id}`)
          .listen('.message_added', (e: unknown) => {
            const data = messageAddedEventSchema.parse(e);

            if (data.event_user_id === user.value.id) return;

            queryClient.setQueryData(
              ['rooms', data.room_hash],
              (oldData: { pages: RoomDetails[] }) => {
                if (!oldData) return;

                const user = oldData.pages[0].users.find(
                  (user) => user.id === data.event_user_id,
                );

                if (!user) return;

                const message = getFormattedEventMessage(data, user);

                return updatePagesWithNewMessage(oldData, message, 1);
              },
            );

            queryClient.setQueryData(
              ['room-list', ''],
              (oldData: {
                pages: { rooms: Room[]; pagination: unknown }[];
              }): { pages: { rooms: Room[]; pagination: unknown }[] } => {
                if (!oldData || oldData.pages.length === 0) {
                  return oldData;
                }

                if (isRoomOnList(oldData, data.room_hash)) {
                  const user = oldData.pages
                    .flatMap((page) => page.rooms)
                    .find((room) => room.hash === data.room_hash)
                    ?.users.find((user) => user.id === data.event_user_id);

                  if (!user) return oldData;

                  const message = getFormattedEventMessage(data, user);

                  return unreadRoom(
                    updateRoomOrder(oldData, message, data.room_hash),
                    data.room_hash,
                  );
                } else {
                  queryClient.invalidateQueries({
                    predicate: (query) => query.queryKey[0] === 'room-list',
                  });
                  return oldData;
                }
              },
            );

            queryClient.invalidateQueries({
              queryKey: ['chat-status'],
            });
          })
          .listen('.room_invite_user', async (e: unknown) => {
            const data = roomInvitedEventSchema.parse(e);

            if (data.event_user_id === user.value.id) return;

            if (data.what_user_id !== user.value.id) {
              const addedUsers = [
                {
                  id: data.what_user_id,
                  name: data.what_user_name,
                  last_name: data.what_user_last_name || '',
                  role: 'coordinator',
                  full_name: `${data.what_user_name} ${data.what_user_last_name || ''}`,
                },
              ];

              queryClient.setQueryData(
                ['room-list', ''],
                (oldData: {
                  pages: { rooms: Room[] }[];
                }): { pages: { rooms: Room[] }[] } => {
                  return updateRoomsUsers(oldData, data.room_hash, addedUsers);
                },
              );

              queryClient.setQueryData(
                ['rooms', data.room_hash],
                (oldData: {
                  pages: RoomDetails[];
                }): {
                  pages: RoomDetails[];
                } => {
                  return updateMessageUsers(oldData, addedUsers);
                },
              );

              return;
            }

            queryClient.invalidateQueries({
              predicate: (query) => query.queryKey[0] === 'room-list',
            });

            queryClient.invalidateQueries({
              queryKey: ['chat-status'],
            });
          });
      }
    },
    { immediate: true },
  );
}
