<script setup lang="ts">
import { useChatChannel } from '~/chat/composables/useChatChannel';

const router = useRouter();
const { token } = useToken();

const { data: user } = useUser();
useChatChannel();

watch(user, () => {
  if (user.value?.roles.some((role) => role.name === 'patient')) {
    token.value = null;

    nextTick(() => {
      router.push({
        name: 'login',
        query: { error: 'patient_forbidden' },
      });
    });
  }
});
</script>

<template>
  <div class="bg-surface-section flex min-h-screen">
    <SideMenu class="" />
    <main class="min-h-screen flex-1 space-y-9 bg-gray-iron-100 p-2 md:px-8">
      <HeaderBar />

      <PrivatePolicyModal v-if="user?.has_accepted_terms === false" />

      <slot></slot>
    </main>
  </div>
</template>
